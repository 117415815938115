import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        // Check if the token is set in localStorage
        const token = localStorage.getItem('token');
        if (token) {
            setIsLoggedIn(true); // Set isLoggedIn to true if token exists
        }
    }, []); // Run only on component mount

    return (
        <header>
            <h1>Welcome to Our Site</h1>
            {!isLoggedIn && (
                <Link to="/login">Login</Link>
            )}
        </header>
    );
};

export default Header;
