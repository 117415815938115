import React, { useEffect, useState } from 'react';
import api from './api';
import Header from './components/Header';

const Home = () => {
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [filterTerm, setFilterTerm] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('token');
        api.get('/all', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            setProducts(response.data.data.products);
            setFilteredProducts(response.data.data.products);
        })
        .catch(error => {
            //setError("Error fetching products.");
        });
    }, []);

    const handleFilterChange = (event) => {
        setFilterTerm(event.target.value);

        // Filter products based on the filter term
        const filtered = products.filter(product =>
            product.product_name.toLowerCase().includes(event.target.value.toLowerCase())
        );

        setFilteredProducts(filtered);
    };

    return (
        <div className='sys-container'>
            <Header />
            <h2>Bid List</h2>
            <input
                type="text"
                placeholder="Search bids..."
                value={filterTerm}
                onChange={handleFilterChange}
            />
            <div className='product-list'>
                {filteredProducts.map(product => (
                    <div className='item' key={product.id}>
                        <div className='title'>{product.product_name}</div>
                        <div className='bid-end-date'>{product.bid_end_date}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Home;
